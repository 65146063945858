<template>
  <div class="wrapper">
    <div class="title" v-if="title">{{ title }}</div>
    <div>
      <!-- <video class="video" src="/a.mp4" autoplay controls></video> -->
      <video ref="videoPlayer" class="video-js"></video>
    </div>
  </div>
</template>

<script>
import { getFileUrl } from "@/api/common";
import "video.js/dist/video-js.css";
import videojs from "video.js";
export default {
  data() {
    return {
      displayList: [],
      loading: false,
      videoData: null,
      player: null,
      playerOptions: {
        aspectRatio: "16:9",
      },
    };
  },
  props: {
    title: String,
    options: Array,
    autoplay: Boolean,
    controls: Boolean,
  },
  computed: {},
  mounted() {
    this.initVideo();
    this.getScr();
  },
  watch: {
    options: {
      deep: true,
      handler() {
        this.getScr();
      },
    },
  },
  methods: {
    getScr() {
      if (!this.options || this.options.length == 0) {
        this.videoData = null;
        return;
      }
      this.loading = true;
      const key = this.options[0].objectKey;
      getFileUrl({
        fileCodeList: key,
      })
        .then((res) => {
          if (res && res.code == 200) {
            this.videoData = res.data[key];
            if (this.player) {
              this.player.src({
                type: "video/mp4",
                src: res.data[key].url,
              });
            }
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    initVideo() {
      this.player = videojs(this.$refs.videoPlayer, {
        ...this.playerOptions,
        autoplay: this.autoplay,
        controls: this.controls,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  margin-bottom: 18px;
}
.title {
  color: #606266;
  font-size: 14px;
  line-height: 28px;
  margin-bottom: 10px;
}
.video-js {
  width: 100%;
  height: auto;
}
</style>
