<template>
  <div class="form">
    <div v-if="detail.showConfig == 1" class="counter">
      <span>{{ cIndex }}/{{ displayRules.length }}</span>
    </div>
    <form-create
      v-if="formatRule && formatRule.length"
      v-model="formCreate.fApi"
      :rule="formatRule"
      :option="formCreate.option"
      :value.sync="dyForm"
      name="form"
      ref="createForm"
      @change="handleValueChange"
    ></form-create>
    <!-- 一页一题 -->
    <div v-if="detail.showConfig == 1" class="actions bottom">
      <div>
        <van-button
          @click="handlePre"
          round
          type="primary"
          plain
          class="prev-btn"
          :disabled="!prevField"
        >
          上一题
        </van-button>
        <van-button
          @click="handleNext"
          round
          type="primary"
          plain
          class="next-btn"
          :disabled="!nextField"
        >
          下一题
        </van-button>
      </div>
      <div>
        <van-button
          @click="handleSubmit"
          class="submmit-btn"
          round
          type="primary"
          :disabled="disabled"
        >
          <span v-if="disabled">已填写</span>
          <span v-else> 提交 </span>
        </van-button>
      </div>
    </div>
  </div>
</template>
<script>
import formCreate from "../FormCreate/index.js";
import intersection from "lodash/intersection";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
export default {
  data() {
    return {
      currentField: "", //一页一题时，当前题目的field
      formatRule: [],
      dyForm: {},
      formCreate: {
        option: {
          form: {
            labelPosition: "top",
            size: "mini",
            // labelWidth: "120px",
            hideRequiredAsterisk: false,
            showMessage: true,
            inlineMessage: false,
            disabled: false,
          },
          submitBtn: false,
          resetBtn: false,
        },
        fApi: {},
      },
    };
  },
  props: {
    rule: Array,
    lastValue: Object,
    detail: Object,
    disabled: Boolean,
  },
  computed: {
    //一页一题时，上一题的field
    prevField() {
      if (!this.currentField) return "";
      const cIndex = this.formatRule.findIndex(
        (el) => el.field === this.currentField
      );
      //截取前边数组
      const prevRules = this.formatRule.slice(0, cIndex).reverse(); //翻转一下
      const prevRule = prevRules.find((el) => !el.hidden && el.field);
      //上一题
      if (prevRule) {
        return prevRule.field;
      } else {
        return "";
      }
    },
    //一页一题时，下一题的field
    nextField() {
      if (!this.currentField) return "";
      const cIndex = this.formatRule.findIndex(
        (el) => el.field === this.currentField
      );
      //截取剩余数组
      const nextRules = this.formatRule.slice(
        cIndex + 1,
        this.formatRule.length
      );
      const nextRule = nextRules.find((el) => !el.hidden && el.field);
      //下一题
      if (nextRule) {
        return nextRule.field;
      } else {
        return "";
      }
    },
    //当前题目排序
    cIndex() {
      if (!this.currentField) return "";
      const cIndex = this.displayRules.findIndex(
        (el) => el.field === this.currentField
      );
      return cIndex + 1;
    },
    //在填全部题目
    displayRules() {
      const rules = this.formatRule.filter((el) => !el.hidden);
      return rules;
    },
  },
  components: {
    FormCreate: formCreate.$form(),
  },
  watch: {
    lastValue(newValue, oldValue) {
      if (newValue) {
        this.dyForm = cloneDeep(newValue);
      }
    },
    currentField() {
      if (this.detail.showConfig == 1) {
        this.displayCurrentField();
      }
    },
    disabled() {
      this.formCreate.option.form.disabled = this.disabled;
    },
  },
  created() {
    this.formCreate.option.form.disabled = this.disabled;
    this.formatRule = cloneDeep(this.rule);
    //字段有关联关系时，不渲染
    this.formatRule.forEach((el) => {
      if (el.relativeRule) el.hidden = true;
      //视频、图片投票结果通过props传进去
      if (
        (el.type === "VoteList" || el.type === "VideoVoteList") &&
        el.props.showResult &&
        this.detail.analyse
      ) {
        const c = this.detail.analyse.find((item) => item.field === el.field);
        el.props.result = c ? c.options : [];
      }
    });

    //一页一题时设置当前显示的field
    if (this.detail.showConfig == 1) {
      // this.currentField = this.formatRule[0].field;
      const first = this.formatRule.find((el) => !el.hidden);
      this.currentField = first ? first.field : "";
      this.displayCurrentField();
    }
  },
  mounted() {
    //设置初始值 mounted设置后触发handleValueChange
    if (this.lastValue) {
      this.dyForm = cloneDeep(this.lastValue);
    }
  },
  methods: {
    handleValueChange() {
      this.formatRule.forEach((ruleItem) => {
        if (ruleItem.relativeRule) {
          this.updateFormView(ruleItem);
        }
      });
    },
    //只显示currentField字段
    displayCurrentField() {
      this.formatRule.forEach((el) => {
        // this.formCreate.fApi.display(false, el.field);
        if (el.field && el.field === this.currentField) {
          el.display = true;
        } else {
          el.display = false;
        }
      });
      // this.formCreate.fApi.display(true, this.currentField);
    },
    //按填写逻辑更新题目状态
    updateFormView(rule) {
      const flags = []; //是否显示
      //每个规则判断
      rule.relativeRule.list.forEach((el) => {
        let flag;
        let field = el.field; //关联项
        let value = this.formCreate.fApi.getValue(field); //关联项的值
        if (typeof value === "string") {
          //关联项单选
          flag = el.value.includes(value) ? true : false;
        } else if (Array.isArray(value)) {
          //关联项多选
          switch (el.judge) {
            //1 其中一个  取交集有值则为true
            case "1":
              flag = intersection(el.value, value).length ? true : false;
              break;
            //2 全部选项  取交集全等与设置值，则为true
            case "2":
              flag = isEqual(intersection(el.value, value), el.value)
                ? true
                : false;
              break;
            default:
              break;
          }
        } else {
          //关联项空值
          flag = false;
        }
        flags.push(flag);
      });

      // console.log(flags);
      //或 且判断
      let flag;
      switch (rule.relativeRule.type) {
        //flags 全为true 显示
        case "&":
          flag = flags.every((el) => el === true);
          break;
        //flags 一个为true 显示
        case "||":
          flag = flags.some((el) => el === true);
          break;
        default:
          break;
      }
      if (flag) {
        //显示
        this.formCreate.fApi.hidden(false, rule.field);
      } else {
        //清除值，隐藏
        this.formCreate.fApi.setValue({ [rule.field]: null });
        // this.formCreate.fApi.removeField(rule.field);
        this.formCreate.fApi.hidden(true, rule.field);
      }
    },
    //动态form校验
    validate() {
      return new Promise((resolve, reject) => {
        this.formCreate.fApi.validate((valid, fail) => {
          if (valid) {
            resolve({
              formValue: JSON.stringify(this.dyForm),
            });
          } else {
            this.$toast("请继续完善填写项");
          }
        });
      });
    },

    //上一题
    handlePre() {
      //先校验当前题
      this.currentField = this.prevField;
    },
    //下一题
    handleNext() {
      //先校验当前题
      // this.formCreate.fApi.validateField(this.currentField).then(() => {
      //   this.currentField = this.nextField;
      // });
      this.formCreate.fApi.validateField(this.currentField, () => {
        this.currentField = this.nextField;
      });
    },
    //提交
    handleSubmit() {
      this.$emit("submit");
    },
  },
};
</script>
<style lang="scss" scoped>
.form {
  background: #fff;
  position: relative;
  /deep/ .el-checkbox-group {
    .el-checkbox {
      display: flex;
      // align-items: flex-start;
      align-items: center;
      margin-bottom: 5px;
      .el-checkbox__label {
        white-space: pre-wrap;
        word-break: break-all;
      }
    }
  }
  /deep/ .el-radio-group {
    .el-radio {
      display: flex;
      // align-items: flex-start;
      align-items: center;
      margin-bottom: 5px;
      .el-radio__label {
        white-space: pre-wrap;
        word-break: break-all;
      }
    }
  }
}
.counter {
  color: #999;
  font-size: 16px;
  text-align: right;
}
.actions {
  display: flex;
  justify-content: space-between;
  .submmit-btn {
    width: 3rem;
  }
  .prev-btn {
    margin-right: 10px;
    width: 1.6rem;
  }
  .next-btn {
    width: 1.6rem;
  }
}
.bottom {
  position: fixed;
  bottom: 0;
  // left: 0;
  right: 0;
  padding: 0.15rem;
  background: #fff;
  z-index: 10;
  box-shadow: 0 0 15px 0 #bbb;
  width: 100%;
  max-width: 800px;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (min-width: 800px) {
  .bottom {
    padding: 15px;
  }
  .actions {
    display: flex;
    justify-content: space-between;
    .submmit-btn {
      width: 200px;
    }
    .prev-btn {
      margin-right: 10px;
      width: 100px;
    }
    .next-btn {
      width: 100px;
    }
  }
}
</style>
<style lang="scss">
.el-cascader__dropdown {
  .el-cascader-menu {
    min-width: 2.4rem;
    width: 2.4rem;
  }
}
.el-select-dropdown__wrap {
  .el-select-dropdown__item {
    max-width: 6rem;
  }
}
</style>
