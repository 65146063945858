<template>
  <div class="draw-wrapper">
    <div class="bg">
      <div class="card detail-card">
        <p class="p1">{{ detail.questionName }}</p>
        <div class="p2">
          <van-tag type="primary" v-if="detail.expiryMsg === '进行中'">
            {{ detail.expiryMsg }}
          </van-tag>
          <van-tag type="warning" v-else>{{ detail.expiryMsg }}</van-tag>
        </div>
        <div class="p3">
          {{ detail.questionDesc }}
        </div>
      </div>
      <div class="card rule-card">
        <p class="p1">抽签规则</p>
        <div class="p2">
          <p>1、每人可以抽{{ detail.drawNum }}次</p>
          <p v-if="detail.expiryDate == 1">
            2、抽签截止时间为{{ detail.endTime }}
          </p>
          <p v-if="detail.expiryDate == 2">2、抽签长期有效</p>
          <p v-if="detail.autoEnd == 1">
            3、满{{ detail.endNum }}人自动结束抽签
          </p>
        </div>
      </div>
      <div class="card result-card">
        <p class="p1">抽签结果</p>
        <div class="p2" v-if="detail.fillList && detail.fillList.length">
          <p
            class="lucky-item"
            v-for="(item, index) in detail.fillList"
            :key="index"
          >
            <span class="name"> {{ index + 1 }}. {{ item.formValue }} </span>
            <span class="time" style="color: #999">
              {{ item.createTime }}
            </span>
          </p>
        </div>
        <div v-else>
          <van-empty description="暂无抽签记录" />
        </div>
      </div>
      <div class="bottom">
        <van-button
          type="primary"
          @click="handleDraw"
          :loading="loading"
          block
          :disabled="detail.drawLeft <= 0 || detail.expiryMsg === '已结束'"
        >
          <span v-if="detail.expiryMsg === '已结束'">活动已结束</span>
          <span v-else-if="detail.drawLeft <= 0">抽签次数已用完</span>
          <span v-else> 点击抽签 剩余（{{ detail.drawLeft }}）次 </span>
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false
    };
  },
  props: {
    detail: Object,
    openId: String
  },
  methods: {
    handleDraw() {
      this.loading = true;
      this.$store
        .dispatch("question/getLuckyDraw", {
          openId: this.openId,
          questionCode: this.detail.questionCode
        })
        .then((res) => {
          this.loading = false;
          this.$dialog.confirm({
            title: "抽签结果",
            message: `${res.data.formValue}`,
            confirmButtonText: "确定",
            showConfirmButton: true,
            showCancelButton: false
          });
          this.$emit("success");
        })
        .catch((err) => {
          this.$toast(err && err.msg ? err.msg : "服务器错误，请稍后再试");
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.draw-wrapper {
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  background-image: url("./pagebg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.bg {
  height: 100%;
  padding: 20px 15px 74px;
  overflow: auto;
  background: linear-gradient(180deg, transparent, #f0f0f0 50%, #f0f0f0);
}
.card {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
}
.detail-card {
  .p1 {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 10px;
  }
  .p2 {
    text-align: center;
    margin-bottom: 10px;
  }
  .p3 {
    font-size: 14px;
    line-height: 1.5;
  }
}
.rule-card {
  .p1 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .p2 {
    line-height: 1.5;
    color: #666;
    font-size: 14px;
  }
}
.result-card {
  .p1 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .p2 {
    line-height: 2;
    font-size: 14px;
  }
  .lucky-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .name {
      flex: 1;
      word-break: break-all;
      white-space: pre-wrap;
    }
    .time {
      width: 150px;
      flex: 0 0 150px;
      text-align: right;
    }
  }
}
.bottom {
  position: fixed;
  left: 0;
  bottom: 0;
  background: #fff;
  padding: 15px;
  box-shadow: 0 0 15px 0 #bbb;
  width: 100%;
  max-width: 800px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0;
}
</style>
