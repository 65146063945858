<template>
  <div class="wrapper">
    <div class="title" v-if="title">{{ title }}</div>
    <div style="width: 100%">
      <van-swipe
        class="my-swipe"
        :autoplay="3000"
        indicator-color="white"
        v-loading="loading"
      >
        <van-swipe-item v-for="item in displayList" :key="item.objectKey">
          <div
            class="img-wrapper"
            :style="{ backgroundImage: `url('${item.url}')` }"
          >
            <!-- <img :src="item.url" alt="" /> -->
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
  </div>
</template>

<script>
import { batchQueryFileUrlBySort } from "@/api/common";
export default {
  data() {
    return {
      displayList: [],
      loading: false,
    };
  },
  props: {
    options: {
      default: () => [],
      type: Array,
    },
    title: "",
  },
  computed: {},
  mounted() {
    this.getFileList();
  },
  watch: {
    options: {
      deep: true,
      handler() {
        this.getFileList();
      },
    },
  },
  methods: {
    getFileList() {
      if (!this.options || !this.options.length) return;
      this.loading = true;
      const keys = this.options.map((el) => el.objectKey).join(",");
      batchQueryFileUrlBySort({
        objectKeyList: keys,
      })
        .then((res) => {
          if (res && res.code == 200) {
            this.displayList = res.data;
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  margin-bottom: 18px;
}
.title {
  color: #606266;
  font-size: 14px;
  line-height: 28px;
  margin-bottom: 10px;
}
.img-wrapper {
  height: 3rem;
  width: 100%;
  overflow: hidden;
  background: #ddd;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  // text-align: center;
  // font-size: 0;
  // background-image: url('');
  // img {
  //   height: 3rem;
  //   width: auto;
  // }
}
</style>
