<template>
  <div class="video-wrapper">
    <!-- <video v-if="videoData" autoplay :src="videoData.url"></video> -->
    <video ref="videoPlayer" class="video-js"></video>
  </div>
</template>

<script>
import { getMediaUrlListByCodes } from "@/api/common/index.js";
import "video.js/dist/video-js.css";
import videojs from "video.js";
export default {
  data() {
    return {
      displayList: [],
      loading: false,
      videoData: null,
      player: null,
      playerOptions: {
        aspectRatio: "16:9",
      },
      controls: true,
      autoplay: false,
    };
  },
  props: {
    options: String,
  },
  computed: {},
  mounted() {
    this.initVideo();
    this.getScr();
  },
  watch: {
    options: {
      deep: true,
      handler() {
        this.getScr();
      },
    },
  },
  methods: {
    getScr() {
      if (!this.options) {
        this.videoData = null;
        return;
      }
      this.loading = true;
      const key = this.options;
      getMediaUrlListByCodes({
        materialCodes: key,
        queryCover: 1,
      })
        .then((res) => {
          if (res && res.code == 200) {
            this.videoData = res.data[0];
            if (this.player) {
              this.player.src({
                type: "application/x-mpegURL",
                src: this.videoData.url,
              });
              this.player.poster(this.videoData.coverImgUrl);
            }
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    initVideo() {
      let _this = this;
      _this.player = videojs(
        _this.$refs.videoPlayer,
        {
          ..._this.playerOptions,
          autoplay: _this.autoplay,
          controls: _this.controls,
        },
        function onPlayerReady() {
          // let num = 1;
          // 监听错误事件(如果其中一个ts文件获取失败，需要进行重试次数限制，并且重新加载视频流，以保证视频能够继续播放)
          this.tech_.on("retryplaylist", () => {
            // console.log("ts文件获取失败404.....");
            this.src(_this.player.currentSrc());
            _this.player.poster(_this.videoData.coverImgUrl);
          });
        }
      );
    },
  },
  beforeDestroy() {
    if (this.player !== null) {
      this.player.dispose(); // dispose()会直接删除Dom元素
    }
  },
};
</script>

<style lang="scss" scoped>
.video-wrapper {
  // margin-bottom: 18px;
}
.video-js {
  width: 100%;
  height: auto;
  /deep/ .vjs-big-play-button {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
