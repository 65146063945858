<!--  -->
<template>
  <div class="wrapper">
    <!-- <TopWrapper
      :topObj="{
        bgColor: '#fff',
        title: '问卷调查',
        txtColor: '#333',
        isBack: true,
      }"
    /> -->
    <div v-if="showForm && detail && !showResult">
      <!-- 问卷、投票 -->
      <div
        class="wrapper-content"
        v-if="
          detail.expiryMsg == '进行中' &&
          (detail.questionType == 1 || detail.questionType == 2)
        "
      >
        <div>
          <div class="section">
            <div class="section-title">{{ detail.questionName }}</div>
            <div class="info" v-if="detail.questionDesc">
              <div>
                <p class="info-cmpy">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ detail.questionDesc }}
                </p>
              </div>
            </div>
          </div>
          <div class="section section-form" v-if="detail && detail.formContent">
            <AnswerForm
              ref="applyForm"
              :rule="rule"
              v-if="rule.length > 0"
              :lastValue="lastValue"
              :detail="detail"
              :disabled="disableForm"
              @submit="handleApplyClick"
            />
            <el-empty v-else description="暂无数据"></el-empty>
            <!-- <AnswerForm ref="applyForm" :rule="detail.formContent ? JSON.parse(detail.formContent) : []" /> -->
          </div>
          <div class="bottom" v-if="rule.length > 0 && detail.showConfig != 1">
            <van-button
              type="primary"
              @click="handleApplyClick"
              :loading="isLoading"
              block
              round
              :disabled="disableForm"
            >
              <span v-if="disableForm">已填写</span>
              <span v-else> 提交 </span>
            </van-button>
          </div>
        </div>
      </div>
      <!-- 抽奖 -->
      <div
        class="wrapper-content"
        v-else-if="
          detail.questionType == 3 &&
          (detail.expiryMsg == '进行中' || detail.expiryMsg == '已结束')
        "
      >
        <LuckyDraw
          v-if="detail"
          :detail="detail"
          :openId="openId"
          @success="handleDrawSuccess"
        />
      </div>
      <div class="wrapper-remak" v-else>
        <el-result icon="info" :subTitle="detail.expiryMsg"></el-result>
      </div>
    </div>

    <!-- 页面二维码 -->
    <van-dialog
      v-model="showPageCode"
      :show-cancel-button="false"
      :show-confirm-button="false"
    >
      <div class="code-wrapper">
        <VueQr v-if="pageUrl" :text="pageUrl" :size="200" :margin="20" />
        <div class="tip">请使用微信扫码填写</div>
      </div>
    </van-dialog>

    <!-- 微信场景二维码登录 -->
    <van-dialog
      v-model="showWxLoginCode"
      :show-cancel-button="false"
      :show-confirm-button="false"
    >
      <div class="code-wrapper">
        <VueQr v-if="wxLoginUrl" :text="wxLoginUrl" :size="200" :margin="20" />
        <div class="tip">请使用微信扫码登录后填写</div>
      </div>
    </van-dialog>

    <div v-if="showResult" class="result">
      <el-result icon="success" title="成功">
        <div style="text-align: center" slot="extra">
          <van-button type="primary" @click="handleResultClick">
            查看结果
          </van-button>
        </div>
      </el-result>
    </div>
  </div>
</template>

<script>
import AnswerForm from "./components/AnswerForm.vue";
import LuckyDraw from "./components/LuckyDraw.vue";
import { questionDetail, fillQuestion } from "@/api/question";
import { wxAuth, isWeiXin } from "@/utils";
import { getToken } from "@/utils/auth";
import VueQr from "vue-qr";
import TopWrapper from "@/components/topWrapper/index.vue";

export default {
  data() {
    return {
      id: "",
      detail: null,
      formContent: null,
      isLoading: false,
      openId: "",
      showPageCode: false, //页面链接二维码弹窗
      pageUrl: window.location.href, //
      showWxLoginCode: false, //微信场景码登录弹窗
      wxLoginUrl: "", //
      loginTimer: null, //轮训微信登录
      lastValue: null, //上次填写内容
      fillCode: "", //上次填写的id
      wxCode: "",
      disableForm: false, //是否编辑表三
      showForm: true, //渲染表单
      showResult: false, //展示提交结果
      changeFill: false, //修改提交
    };
  },

  components: { AnswerForm, VueQr, LuckyDraw ,TopWrapper},
  provide() {
    return {
      disableForm: () => this.disableForm,
    };
  },
  computed: {
    rule() {
      return this.detail.formContent ? JSON.parse(this.detail.formContent) : [];
    },

    token() {
      return getToken();
    },
  },
  created() {
    const { id } = this.$route.params;
    const { code } = this.$route.query;
    const { fillCode } = this.$route.query;
    if (!this.token) {
      this.$router.push({
        path: "/login",
        query: {
          id: `/question/${id}`,
          fillCode: fillCode,
        },
      });
      return;
    }
    if (fillCode) {
      this.fillCode = fillCode;
    }
    if (id) {
      this.id = id;
      this.initDetail(id);
      //if (this.detail.expiryMsg == "进行中" && this.detail.repeatFill != "2") {
      this.checkRepeatFill();
      //}
    }
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    //抽奖成功，刷新
    handleDrawSuccess() {
      this.getDrawdetail();
    },
    //获取抽奖详情
    getDrawdetail() {
      this.$store
        .dispatch("question/questionDetail", {
          questionCode: this.id,
          openId: this.openId,
          fillCode: this.fillCode,
        })
        .then((res) => {
          this.detail = res.data;
          this.showForm = true;
          this.$store.commit("question/setDetail", this.detail);
        });
    },
    //检查能否重复填写
    checkRepeatFill() {
      this.$store
        .dispatch("question/questionDetail", {
          questionCode: this.id,
          openId: this.openId,
          fillCode: this.fillCode,
        })
        .then((res) => {
          this.detail = res.data;
          this.showForm = true;
          this.$store.commit("question/setDetail", this.detail);
          if (
            this.detail.expiryMsg == "进行中" &&
            this.detail.repeatFill != "2"
          ) {
            this.showCheckConfirm();
          }
          if (
            this.detail.expiryMsg == "进行中" &&
            this.detail.repeatFill == "2"
          ) {
            if (this.detail.last) {
              //查看投票结果
              this.lastValue = JSON.parse(this.detail.last.formValue);
              this.showResult = false;
              this.disableForm = true;
            }
          }
        });
    },
    //弹窗提醒
    showCheckConfirm() {
      if (this.detail.last) {
        if (this.detail.questionType == 1) {
          //问卷
          this.$dialog
            .confirm({
              message: "你已经填写过该问卷",
              confirmButtonText: "继续填写",
              showConfirmButton: this.detail.repeatFill == 1 ? true : false, //1 可重复填写
              confirmButtonColor: "#3c37d2",
              cancelButtonText: "修改上次提交信息",
              cancelButtonColor: "red",
              showCancelButton: true,
            })
            .then(() => {
              //继续填写
              setTimeout(() => {
                this.continueFill();
              }, 300);
            })
            .catch(() => {
              if (this.detail.last) {
                // 修改上次
                this.lastValue = JSON.parse(this.detail.last.formValue);
                this.fillCode = this.detail.last.fillCode;
                this.changeFill = true;
              }
            });
        } else {
          //投票直接展示结果
          this.$dialog
            .confirm({
              message: "你已经参与过该投票",
              confirmButtonText: "查看投票结果",
              confirmButtonColor: "#3c37d2",
              showCancelButton: false,
            })
            .then(() => {
              //查看投票结果
              if (this.detail.last) {
                this.lastValue = JSON.parse(this.detail.last.formValue);
                this.disableForm = true;
              }
            });
        }
      }
    },

    continueFill() {
      this.$dialog
        .confirm({
          message: "是否载入上次回答？",
          confirmButtonText: "是",
          confirmButtonColor: "#3c37d2",
          cancelButtonText: "否",
          cancelButtonColor: "red",
          showCancelButton: true,
        })
        .then(() => {
          if (this.detail.last) {
            this.lastValue = JSON.parse(this.detail.last.formValue);
          }
        });
    },
    /* 初始化获取详情 */
    initDetail(id) {
      questionDetail({ questionCode: id, fillCode: this.fillCode }).then(
        (res) => {
          let { data, success, code } = res;
          if (success) {
            this.detail = data;
            document.title = this.detail.questionName;
            this.$store.commit("question/setDetail", this.detail);
            this.checkAuth();
          }
        }
      );
    },
    //检查权限
    checkAuth() {
      //1,2,不是进行中，只展示状态
      if (
        this.detail.expiryMsg != "进行中" &&
        (this.detail.questionType == 1 || this.detail.questionType == 2)
      ) {
        this.showForm = true;
        return;
      }
      //3 抽奖 不是进行中，不是已结束，只展示状态
      if (
        this.detail.expiryMsg != "进行中" &&
        this.detail.expiryMsg != "已结束" &&
        this.detail.questionType == 3
      ) {
        this.showForm = true;
        return;
      }
    },
    /* 提交 */
    async handleApplyClick() {
      const validateData = await this.$refs.applyForm.validate();

      if (validateData) {
        this.$dialog
          .confirm({
            title: "提示",
            message: "确定要提交问卷吗？",
          })
          .then(() => {
            this.isLoading = true;
            fillQuestion({
              ...validateData,
              questionCode: this.detail.questionCode,
              openId: this.openId,
              fillCode: this.changeFill ? this.fillCode : "",
            })
              .then((res) => {
                let { success, msg } = res;
                this.isLoading = false;
                if (success) {
                  this.showResult = true;
                } else {
                  this.$message.error(msg);
                }
              })
              .catch((err) => {
                this.isLoading = false;
              });
          });
      }
    },
    //查看结果
    handleResultClick() {
      this.$store
        .dispatch("question/questionDetail", {
          questionCode: this.id,
          openId: this.openId,
        })
        .then((res) => {
          this.detail = res.data;
          //查看投票结果
          if (this.detail.last) {
            this.lastValue = JSON.parse(this.detail.last.formValue);
            this.showResult = false;
            this.disableForm = true;
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  box-sizing: border-box;
  // background: #f3f3f3;
  overflow: hidden;
  max-width: 800px;
  margin: 0 auto;
  .wrapper-content {
    padding-bottom: 60px;
  }
  .section {
    padding: 10px;
    background: #fff;
    margin-bottom: 10px;
    .section-title {
      font-size: 16px;
      font-weight: bold;
      line-height: 1.5;
      text-align: center;
    }
    .section-form {
      padding-bottom: 60px;
      overflow: auto;
    }
  }
  .info {
    margin-top: 10px;
    background: #fff;
    display: flex;
    .info-cmpy {
      font-size: 13px;
      color: #666;
      line-height: 1.5;
    }
  }
}
.code-wrapper {
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.pagecode-img {
  width: 200px;
  height: 200px;
  margin-bottom: 20px;
}
.tip {
  font-size: 14px;
  color: #666;
  line-height: 2;
}

.bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
  background: #fff;
  z-index: 10;
  box-shadow: 0 0 15px 0 #bbb;
  width: 100%;
  max-width: 800px;
  left: 50%;
  transform: translateX(-50%);
}

.result {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 99;
  background: #fff;
}
@media screen and (min-width: 800px) {
  .wrapper {
    background: #fff;
    padding-top: 30px;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
  }
  .wrapper-content {
    background: #fff;
  }
}
</style>
