<template>
  <div class="img-wrapper">
    <img
      class="img"
      v-for="item in displayList"
      :key="item.objectKey"
      :src="item.url"
    />
  </div>
</template>

<script>
import { getMediaUrlListByCodes } from "@/api/common/index.js";
export default {
  data() {
    return {
      displayList: [],
      loading: false,
    };
  },
  props: {
    options: String,
    title: "",
  },
  computed: {},
  mounted() {
    this.getFileList();
  },
  watch: {
    //素材codes  ,分隔
    options: {
      deep: true,
      handler() {
        this.getFileList();
      },
    },
  },
  methods: {
    getFileList() {
      if (!this.options) {
        this.displayList = [];
        return;
      }
      this.loading = true;
      const keys = this.options;
      getMediaUrlListByCodes({
        materialCodes: keys,
      })
        .then((r) => {
          // console.log(r);
          if (r && r.code == 200) {
            this.displayList = r.data;
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.img-wrapper {
  font-size: 0;
  line-height: 0;
}
.img {
  max-height: 200px;
  max-width: 100%;
  margin-bottom: 10px;
  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }
  // width: auto;
}
</style>
