<template>
  <el-cascader
    :props="props"
    v-model="casValue"
    placeholder="请选择地区"
    @change="handleChange"
  ></el-cascader>
</template>

<script>
import {
  selectAllProvince,
  selectByProvinceCode,
  selectByCityCode,
} from "@/api/common";

export default {
  props: {
    value: [Array, Object],
  },
  data() {
    return {
      // value: null,
      props: {
        lazy: true,
        lazyLoad: this.lazyLoad,
      },
    };
  },
  computed: {
    casValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  mounted() {},

  watch: {},
  methods: {
    handleChange(e) {},
    // 三级联动树 懒加载
    lazyLoad(node, resolve) {
      const { level } = node;
      level === 0 &&
        this.axiosSelectAllProvince().then((res) => {
          let nodes = res.map((item) => ({
            value: item.proCode,
            label: item.proName,
            leaf: level >= 2,
          }));
          this.provinceList = nodes;
          resolve(nodes);
        });
      level === 1 &&
        this.axiosSelectByProvinceCode(node.value).then((res) => {
          const nodes = res.map((item) => ({
            value: item.cityCode,
            label: item.cityName,
            leaf: level >= 2,
          }));
          this.cityList = nodes;
          resolve(nodes);
        });
      level === 2 &&
        this.axiosSelectByCityCode(node.value).then((res) => {
          let nodes = [];
          res.map((item) => {
            nodes.push({
              value: item.countryCode,
              label: item.countryName,
              leaf: level >= 2,
            });
          });
          this.countryList = nodes;
          resolve(nodes);
        });
    },
    axiosSelectAllProvince() {
      return selectAllProvince().then((res) => {
        if (res.code === 200) {
          return res.data;
        }
      });
    },
    axiosSelectByProvinceCode(proCode) {
      return selectByProvinceCode(proCode).then((res) => {
        if (res.code === 200) {
          return res.data;
        }
      });
    },
    axiosSelectByCityCode(cityCode) {
      return selectByCityCode(cityCode).then((res) => {
        if (res.code === 200) {
          return res.data;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
