<template>
  <div
    class="upload-wrapper"
    v-if="show"
    :class="showUpload ? 'show-upload' : 'hide-upload'"
  >
    <!-- v-show="showUpload()" -->
    <el-upload
      list-type="picture-card"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      :action="action"
      :multiple="multiple"
      :file-list="fileList"
      :on-success="handleUploadSuccess"
      :on-error="handleUploadError"
      :on-change="handleUploadChange"
      :on-exceed="handleExceed"
      name="multipartFile"
      :data="extra"
      :before-upload="beforeUpload"
      :headers="headers"
      :limit="limit"
      :accept="accept"
    >
      <i class="el-icon-plus"></i>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible" title="图片预览" :modal="false">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>
<script>
import Cookies from "js-cookie";

export default {
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,

      extra: {
        materialType: "1",
        returnUrl: "1",
      },
      headers: {},
      action: process.env.VUE_APP_API_URL + "/backend-common/third/uploadImage",
      maxSize: 100, //m
      accept: "image/jpg,image/png,image/jpeg",
      show: true,
    };
  },
  props: {
    // defaultFileList: Array, //默认列表[{name,url,...}]
    // maxSize: Number, //图片大小  最大M
    // accept: String, //类型
    limit: Number, //数量限制
    // action: String,
    multiple: { type: Boolean, default: false },
    value: { type: Array, default: () => [] }, //文件列表
  },
  computed: {
    fileList: {
      get() {
        return this.value || [];
      },
      set(list) {
        this.$emit("input", list);
      },
    },
    showUpload() {
      if (this.limit == 0) return true;
      if (this.fileList.length < this.limit) return true; //所选未传满显示
      return false;
    },
  },
  watch: {},
  mounted() {
    if (this.defaultFileList) {
      this.fileList = [...this.defaultFileList];
    }
    // 请求携带自定义token
    this.headers = {
      Authorization: localStorage.getItem("AuthorizationLingGong"),
      "User-Source": "XCX",
    };
  },
  methods: {
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleUploadChange(file, fileList) {},
    beforeUpload(file, fileList) {
      if (this.accept && this.accept.indexOf(file.type) == -1) {
        this.$message(`文件格式不正确,请上传${this.accept}格式文件`);
        return false;
      }
      if (file.size > this.maxSize * 1024 * 1024) {
        this.$message(`文件大小不能超过${this.maxSize}M`);
        return false;
      }
    },

    handleExceed(file, fileList) {
      this.$message(`文件总数量不能超过 ${this.limit} 个`);
    },
    //删除
    handleRemove(file, fileList) {
      this.fileList = fileList;
      // this.$emit("input", this.fileList);
    },
    //上传成功
    handleUploadSuccess(response, file, fileList) {
      let list = [];
      fileList.forEach((el) => {
        if (el.response) {
          if (el.response.code === 200) {
            list.push({
              name: el.name,
              url: el.response.data.url,
              objectKey: el.response.data.objectKey,
            });
          } else {
            if (el.response.code != 200) {
              this.$message("图片未审核通过，请检查");
            }
          }
        } else {
          list.push({
            ...el,
          });
        }
      });
      this.fileList = list;
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    handleUploadError(response) {
      this.$message({
        type: "error",
        message: "上传失败",
      });
    },
  },
};
</script>
<style scoped lang="scss">
.upload-wrapper {
  /deep/ .el-upload-list--picture-card .el-upload-list__item {
    width: 100px;
    height: 100px;
  }
  /deep/ .el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
  &.hide-upload /deep/ .el-upload--picture-card {
    display: none;
  }
}
</style>
