<template>
  <div class="wrapper">
    <div v-if="!imgSrc" class="click-area" @click="sign">点击签名</div>
    <div class="img-wrapper" v-else>
      <span class="close" @click="del">
        <van-icon name="close" color="#999" />
      </span>
      <img class="img" :src="imgSrc" alt="" />
    </div>
    <van-popup v-model="visible" @opened="handleOened" :overlay="false">
      <div class="canvas-wrapper" ref="wrapper">
        <canvas
          class="canvas"
          :width="width"
          :height="height"
          ref="sign-canvas"
          v-if="showCanvas"
          :id="id"
        ></canvas>
        <div class="btns">
          <van-button
            style="margin-right: 10px"
            icon="brush-o"
            @click="clearCanvas"
            round
          >
          </van-button>
          <van-button style="margin-right: 10px" @click="visible = false" round>
            关闭
          </van-button>
          <van-button
            @click="submit"
            type="primary"
            :disabled="disableSubmit"
            round
          >
            保存
          </van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { fabric } from "fabric";

function guid(len = 32, firstU = true, radix = null) {
  let chars =
    "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split("");
  let uuid = [];
  radix = radix || chars.length;

  if (len) {
    // 如果指定uuid长度,只是取随机的字符,0|x为位运算,能去掉x的小数位,返回整数位
    for (let i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
  } else {
    let r;
    // rfc4122标准要求返回的uuid中,某些位为固定的字符
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = "-";
    uuid[14] = "4";

    for (let i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16);
        uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
      }
    }
  }
  // 移除第一个字符,并用u替代,因为第一个字符为数值时,该guuid不能用作id或者class
  if (firstU) {
    uuid.shift();
    return "u" + uuid.join("");
  } else {
    return uuid.join("");
  }
}
export default {
  data() {
    return {
      canvas: null,
      id: guid(5),
      visible: false,
      width: 0,
      height: 0,
      showCanvas: false,
      imgSrc: "",
      disableSubmit: true,
    };
  },
  props: {
    value: String,
  },
  computed: {},
  mounted() {},
  watch: {},
  methods: {
    sign() {
      this.visible = true;
    },
    handleOened() {
      if (this.canvas) return;
      this.initCanvas();
    },
    initCanvas() {
      this.width = this.$refs.wrapper.clientWidth;
      this.height = this.$refs.wrapper.clientHeight;
      this.showCanvas = true;
      this.$nextTick(() => {
        this.canvas = new fabric.Canvas(this.id, {
          isDrawingMode: true,
        });
        this.canvas.freeDrawingBrush.width = 5;
        this.canvas.on("mouse:move", (options) => {
          this.disableSubmit = false;
        });
      });
    },
    clearCanvas() {
      if (!this.canvas) return;
      this.disableSubmit = true;
      this.canvas.clear();
    },
    submit() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
      });
      const dataURL = this.canvas.toDataURL({ format: "png", quality: 0.8 });
      this.$store
        .dispatch("common/uploadBs64ImageFile", {
          base64Code: dataURL,
          degree: -90,
        })
        .then((res) => {
          this.$toast.clear();
          this.imgSrc = res.data.url;
          this.$emit("input", res.data.objectKey);
          this.visible = false;
          this.clearCanvas();
        })
        .catch((err) => {
          this.$toast.clear();
        });
    },
    del() {
      this.imgSrc = "";
      this.$emit("input", "");
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  height: 2rem;
  overflow: hidden;
}
.click-area {
  width: 4rem;
  height: 2rem;
  background: #f6f6f6;
  color: #aaa;
  border: 1px dashed #dcdfe6;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.canvas-wrapper {
  width: 100vw;
  height: 100%;
  position: relative;
  overflow: hidden;
  .canvas {
    width: 100vw;
    height: 100%;
  }
  .btns {
    transform: rotate(90deg) translateX(-100%);
    transform-origin: left bottom;
    position: fixed;
    bottom: 0.4rem;
    left: 0.4rem;
    transform: rotate(90deg) translateX(-100%);
  }
}
.img-wrapper {
  position: relative;
  // transform: rotate(-90deg) translateX(-100%);
  // transform-origin: left top;
  height: 2rem;
  display: inline-block;
  // background: #f3f3f3;
  border: 1px dashed #dcdfe6;
  background: #f6f6f6;
  overflow: hidden;
  border-radius: 4px;
  .close {
    position: absolute;
    top: 0.1rem;
    right: 0.1rem;
    line-height: 1;
  }
  .img {
    height: 2rem;
    width: auto;
  }
}
</style>
