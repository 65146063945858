import formCreate from "@form-create/element-ui";
import UploadImg from "./components/UploadImg";
import AreaSelect from "./components/AreaSelect";
import UserIdcard from "./components/UserIdcard";
import UserName from "./components/UserName";
import UserPhone from "./components/UserPhone";
import UserSign from "./components/UserSign";
import UserLocation from "./components/UserLocation";
import SwiperImg from "./components/SwiperImg";
import VideoMedia from "./components/VideoMedia";
import VoteList from "./components/VoteList";
import VideoVoteList from "./components/VideoVoteList";
formCreate.component("UploadImg", UploadImg);
formCreate.component("AreaSelect", AreaSelect);
formCreate.component("UserIdcard", UserIdcard);
formCreate.component("UserName", UserName);
formCreate.component("UserPhone", UserPhone);
formCreate.component("UserSign", UserSign);
formCreate.component("UserLocation", UserLocation);
formCreate.component("SwiperImg", SwiperImg);
formCreate.component("VideoMedia", VideoMedia);
formCreate.component("VoteList", VoteList);
formCreate.component("VideoVoteList", VideoVoteList);
export default formCreate;
