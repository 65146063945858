<template>
  <div class="wrapper">
    <div class="text" v-if="formattedAddress">{{ formattedAddress }}</div>
    <el-button @click="getLocation" type="text" :loading="loading">
      获取定位
    </el-button>
  </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
import { getLocation } from "@/api/common";
export default {
  data() {
    return {
      inputValue: "",
      loading: false,
    };
  },
  props: {
    value: Object,
  },
  computed: {
    formattedAddress() {
      return this.value ? this.value.formatted_addresses.recommend : "";
    },
  },
  mounted() {},
  watch: {},
  methods: {
    getLocation() {
      this.loading = true;
      AMapLoader.load({
        key: "013ff483e851b64039b2ea97321930f6",
        version: "2.0",
        plugins: ["AMap.Geolocation"],
      })
        .then((AMap) => {
          var geolocation = new AMap.Geolocation({
            // 是否使用高精度定位，默认：true
            enableHighAccuracy: false,
            // 设置定位超时时间
            timeout: 10000,
          });

          geolocation.getCurrentPosition((status, result) => {
            // console.log(result);
            if (status == "complete") {
              // this.onComplete(result);
              if (result.position) {
                getLocation({
                  longitude: result.position.lng,
                  latitude: result.position.lat,
                })
                  .then((res) => {
                    if (res.code === 200) {
                      this.$emit("input", {
                        ad_info: res.data.ad_info,
                        address: res.data.address,
                        address_component: res.data.address_component,
                        formatted_addresses: res.data.formatted_addresses,
                        address_reference: res.data.address_reference,
                        location: res.data.location,
                      });
                    }
                    this.loading = false;
                  })
                  .catch((err) => {
                    this.loading = false;
                    this.onError(result);
                  });
              } else {
                this.loading = false;
                this.onError(result);
              }
            } else {
              this.loading = false;
              this.onError(result);
            }
          });
        })
        .catch((e) => {
          console.error(e);
        });
    },
    onComplete(data) {
      const value = {
        position: data.position,
        accuracy: data.accuracy,
        addressComponent: data.addressComponent,
        formattedAddress: data.formattedAddress,
      };
      this.$emit("input", value);
    },
    onError(data) {
      this.$message("定位失败");
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  .text {
    margin-bottom: 10px;
    font-size: 14px;
    color: #666;
  }
}
</style>
