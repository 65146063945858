<template>
  <div class="wrapper">
    <!-- <div class="title" v-if="title">
      <span>
        {{ title }}
      </span>
    </div> -->
    <div class="description" v-if="description">{{ description }}</div>
    <div
      v-for="(item, index) in list"
      :key="item.id"
      :class="['item', selectValue.includes(item.id) ? 'checked' : '']"
      @click="handleClick(item, index)"
    >
      <div class="item-title">
        <div>
          <span> {{ index + 1 }}. {{ item.title }} </span>
          <span v-if="showResult">
            (票数：{{ getItemResult(item).num || 0 }}票,{{
              getItemResult(item).ratio
            }}%)
          </span>
        </div>

        <span v-if="selectValue.includes(item.id)">
          <i class="iconfont iconselect"></i>
        </span>
      </div>

      <div v-if="item.imgs">
        <VideoPlayer :options="item.imgs" />
      </div>
      <van-progress
        v-if="showResult && getItemResult(item).ratio > 0"
        :percentage="getItemResult(item).ratio"
        stroke-width="5"
        :show-pivot="false"
      />
    </div>
  </div>
</template>

<script>
import VideoPlayer from "./VideoPlayer.vue";
export default {
  data() {
    return {};
  },
  components: {
    VideoPlayer,
  },
  inject: ["disableForm"],
  props: {
    //投票项列表
    list: {
      default: () => [],
      type: Array,
    },
    //标题
    title: String,
    //描述
    description: String,
    //显示投票结果
    showResult: {
      type: Boolean,
      default: false,
    },
    //投票结果
    value: {
      default: () => [],
      type: Array,
    },
    //每人票数
    count: {
      default: 1,
      type: Number,
    },
    //投票结果
    result: Array,
    // disabled: Boolean
  },
  inject: ["disableForm"],
  computed: {
    selectValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    disabled() {
      return this.disableForm();
    },
  },
  mounted() {},
  watch: {},
  methods: {
    handleClick(item, index) {
      if (this.disabled) return;
      let arr = this.selectValue;
      if (arr.includes(item.id)) {
        arr = [];
      } else {
        arr.push(item.id);
        arr = [item.id];
      }
      this.$emit("input", arr);
    },
    sum(arr) {
      var s = 0;
      for (var i = arr.length - 1; i >= 0; i--) {
        s += arr[i];
      }
      return s;
    },
    getItemResult(item) {
      let num, ratio;
      if (!this.result) {
        num = 0;
        ratio = 0;
      } else {
        const c = this.result.find((el) => el.field === item.id);
        num = c ? c.num : 0;
        let total = this.sum(this.result.map((el) => el.num));

        if (total == 0) {
          ratio = 0;
        } else {
          ratio = Math.ceil((num / total) * 100);
        }
      }

      return { num, ratio: ratio };
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  margin-bottom: 18px;
}
.title {
  color: #606266;
  font-size: 14px;
  line-height: 28px;
  margin-bottom: 10px;
}
.item {
  border: 1px dashed #3c37d2;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  &.checked {
    background: #e7e7e7;
  }
  .item-title {
    font-size: 14px;
    line-height: 28px;
    margin-bottom: 10px;
    white-space: pre-wrap;
    word-break: break-all;
    display: flex;
    justify-content: space-between;

    .iconfont {
      font-size: 30px;
      line-height: 28px;
      color: #3c37d2;
      font-weight: bold;
      margin-left: 5px;
    }
  }
  .item-img {
    height: 200px;
    width: auto;
  }
}
</style>
