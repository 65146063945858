<template>
  <el-input v-model="inputValue" :readonly="readonly"></el-input>
</template>

<script>
import { getUserInfoByType } from "@/api/common";
export default {
  props: {
    value: String,
    disabled: Boolean,
    readonly: Boolean
  },
  data() {
    return {};
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },
    //小程序环境
    isWxMiniApp() {
      return this.$store.state.global.isWxMiniApp;
    }
  },
  mounted() {
    if (this.isWxMiniApp) {
      this.getUserInfoByType();
    }
  },
  watch: {},
  methods: {
    getUserInfoByType() {
      getUserInfoByType({ type: 3 }).then((res) => {
        if (res && res.code == 200) {
          if (res.data) {
            this.inputValue = res.data.usrTel;
          }
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
